<template>
  <v-app style="background-color:#f8f9fb" >


  <v-dialog v-model="showFilter" persistent max-width="300">
    <v-card>
      <v-card-title>
       Mensagem
      </v-card-title>
      <v-card-text class="d-flex justify-center">
        A Diferença entre as datas não pode ser superior a 365 dias.
      </v-card-text>
      <v-card-actions class="d-flex justify-center"> <!-- Centraliza horizontalmente -->
        <v-btn color="primary" @click="showFilter = false">Fechar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <v-dialog v-model="showFilterDownload" persistent max-width="300">
    <v-card>
      <v-card-title>
       Mensagem
      </v-card-title>
      <v-card-text class="d-flex justify-center">
        Em Manutenção
      </v-card-text>
      <v-card-actions class="d-flex justify-center"> <!-- Centraliza horizontalmente -->
        <v-btn color="primary" @click="showFilterDownload = false">Fechar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>


    <v-container style="background-color:#f8f9fb;" >
      <v-col cols="12">
        <filtro-component :opcoes="opcoes" :produtos="tipos_funerarios" :modos="modos"  :tipos="tipos" :calendar="'mes'"
        @filtrar="filterData" :title_opcao="'Campanha'" :campos="'N'"
          ></filtro-component>
      </v-col>
    </v-container>


  <v-container style="background-color:#eee"  
    id="financeiro"
    fluid
    tag="section">
   
    <v-row>
    <v-col v-if="false"
    cols="12"
    sm="6"
    lg="4">


    
    <base-material-stats-card-detail elevation="1" 
         color="orange" 
         icon="mdi-sale"
         subIcon="mdi-information"
         subIconColor="blue"
         title="Previsão de Receita - Competência"
         sub-icon-color="black"
         :valuex="this.bool_01"
         :value="formatarMoeda(this.esperado_periodo_v2)"
         :sub-text="'Documentos Ativos com Vencimento no Período'"
         :subTextAdd="subTextAddDynamic"
         :subTextAddV="formatarMoeda(this.recebido_periodo_vencimento_v2) + ' - ' + calcularPercentual(this.recebido_periodo_vencimento_v2, this.esperado_periodo_v2 ) + '%'"
         :subTextAdd1="'Adiantamento de pagamento: '"
         :subTextAdd1V="formatarMoeda(this.recebido_adiantado_vencimento_v2) + ' - ' + calcularPercentual(this.recebido_adiantado_vencimento_v2, this.esperado_periodo_v2 ) + '%'"
         :subTextAdd2="'Acordos Recebimento: '"
         :subTextAdd2V="formatarMoeda(recebido_periodo_vencimento_acordos_v2) + ' - ' + calcularPercentual(this.recebido_periodo_vencimento_acordos_v2, this.esperado_periodo_v2 ) + '%'"
         :subTextAdd3="'Vendas: '"
         :subTextAdd3V="formatarMoeda(this.recebido_periodo_vencimento_venda_v2) + ' - ' + calcularPercentual(this.recebido_periodo_vencimento_venda_v2, this.esperado_periodo_v2 ) + '%'"
         :subTextAdd4="'Taxas: '"
         :subTextAdd4V="formatarMoeda(this.recebido_periodo_vencimento_taxa_v2) + ' - ' + calcularPercentual(this.recebido_periodo_vencimento_taxa_v2, this.esperado_periodo_v2 ) + '%'"
         :subTextAdd5="'Preço Médio Plano: '"
         :subTextAdd5V="formatarMoeda(this.esperado_ticket_medio_plano_periodo_v2)"
         :subTextAdd6="'Preço Médio Jazigo: '"
         :subTextAdd6V="formatarMoeda(this.esperado_ticket_medio_jazigo_periodo_caixa_v2)"
         :downloadFunction="download_card1"
         >

         </base-material-stats-card-detail>
    
    
   </v-col>

   
  
      <v-col
      cols="12"
      sm="6"
      lg="4">
     
      <base-material-stats-card-detail elevation="1"
      color="blue"
     icon="mdi-safe"
     subIcon="mdi-information"
     subIconE="mdi-information"
     subIconColor="blue"
     title="Total Recebido"
     sub-icon-color="black"
     :value="formatarMoeda(this.recebido_periodo_v2)"
     :valuex="this.bool_02" 
     :sub-text="'Recebimento - Caixa'"
     :subTextE="'Qtd de Documentos: ' + this.qtd_doc_recebido_periodo_v2"
     :subTextAdd="'Vencimento no Período: '"
     :subTextAddV="formatarMoeda(this.recebido_periodo_vencimento_v2) + ' - ' + calcularPercentual(this.recebido_periodo_vencimento_v2, this.recebido_periodo_v2 ) + '%'"
     :subTextAdd1="'Adiantamento de Pagamento: '"
     :subTextAdd1V="formatarMoeda(this.recebido_periodo_vencimento_posterior_v2) + ' - ' + calcularPercentual(this.recebido_periodo_vencimento_posterior_v2, this.recebido_periodo_v2 ) + '%'"
     :subTextAdd2="'Recebimento em Atraso:' "
     :subTextAdd2V="formatarMoeda(this.recebido_periodo_vencimento_anterior_v2) + ' - ' + calcularPercentual(this.recebido_periodo_vencimento_anterior_v2, this.recebido_periodo_v2 ) + '%'"
     :subTextAdd3="'Vendas: (Planos e Jazigo Imediato)'"
     :subTextAdd3V="formatarMoeda(this.recebido_periodo_vencimento_venda_caixa_v2) + ' - ' + calcularPercentual(this.recebido_periodo_vencimento_venda_caixa_v2, this.recebido_periodo_v2 ) + '%'"
     :subTextAdd4="'Taxas: '"
     :subTextAdd4V="formatarMoeda(this.recebido_periodo_vencimento_taxa_caixa_v2) + ' - ' + calcularPercentual(this.recebido_periodo_vencimento_taxa_caixa_v2, this.recebido_periodo_v2 ) + '%'"
     :subTextAdd5="'Mongeral: (Não Contabiliza)'"
     :subTextAdd5V="formatarMoeda(this.recebido_periodo_mongeral) + ' - ' + calcularPercentual(this.recebido_periodo_mongeral, this.recebido_periodo_v2 ) + '%'"
     :subTextAdd6="'Preço Médio Plano: '"
     :subTextAdd6V="formatarMoeda(this.esperado_ticket_medio_plano_periodo_caixa_v2)"
     :subTextAdd7="'Preço Médio Jazigo: '"
     :subTextAdd7V="formatarMoeda(this.esperado_ticket_medio_jazigo_periodo_caixa_v2)"
     :downloadFunction="download_card1"
     :subTextAdd3E="'x'"

     />
     

       
      
     </v-col>

     <v-col
     cols="12"
      sm="6"
      lg="4">
     
      <base-material-stats-card-detail elevation="1"
          color="red"
          icon="mdi-alert"
          subIcon="mdi-information"
          subIconColor="blue"
          subIconE="mdi-information"

          title="Inadimplência Atual"
          sub-icon-color="black"
          :value="formatarMoeda(this.inadimplencia_acumulda_aberto_v2)"
          :valuex="this.bool_03"
          :sub-text="'Total de Documentos em Aberto'"
          :subTextE="'Qtd de Documentos: ' + this.qtd_inadimplencia_acumulda_aberto_v2"


          
          :subTextAdd="'Inadimplência Acumulada em (' +  this.selectedEndDate + ')'"
          :subTextAddV="formatarMoeda(this.inadimplencia_acumulda_v2)  + ' - ' + 100 + '%'"
          :subTextAdd1="'Total Recuperado - Após (' +  this.selectedEndDate + ')'"
          :subTextAdd1V="formatarMoeda(this.inadimplencia_acumulda_pago_posterior_v2)  + ' - ' + calcularPercentual(this.inadimplencia_acumulda_pago_posterior_v2, this.inadimplencia_acumulda_v2 ) + '%'"
        
          :subTextAdd2="'Inadimplência de Acordos:' "
          :subTextAdd2V="formatarMoeda(this.inadimplencia_acumulda_aberto_acordo_v2)"
        
          :subTextAdd3="'Contratos com Resultado Negativo:' "
          :subTextAdd3V="'0'"
          :subTextAdd4="'Contratos com Utilização do Serviço:' "
          :subTextAdd4V="'0'"
          :subTextAdd5="'Contratos com Seguro:' "
          :subTextAdd5V="this.recebido_comseguro_inadimplencia_periodo_v2"
          :subTextAdd6="'Contratos sem Seguro:' "
          :subTextAdd6V="this.recebido_semseguro_inadimplencia_periodo_v2"

          :subTextAdd7="'Perdas por Cancelamento:' "
          :subTextAdd7V="formatarMoeda(this.perda_cancelamento)"
          :downloadFunction="download_card1"
          :subTextAdd3E="'x'"


          />       
     
    </v-col>


    <v-col
     cols="12"
      sm="6"
      lg="4">
     
      <base-material-stats-card-detail elevation="1"
          color="orange"
          icon="mdi-handshake"
          subIcon="mdi-information"
          subIconE="mdi-information"

          subIconColor="blue"
          title="Recebido com Renegociações"
          sub-icon-color="black"
          :value="formatarMoeda(this.recebido_acordo)"
          :valuex="this.bool_03"
          :sub-text="'Recebido com Acordos no Período'"
          :subTextE="'Qtd de Documentos: ' + this.qtd_recebido_acordo"
          :subTextAdd="'Recebido de Acordos Novos - 1 Parcela: '"
          :subTextAddV="formatarMoeda(this.total_recebimento_acordo_novos_unico)"
          :subTextAdd1="'Recebido de Acordos Novos - Parcelado: '"
          :subTextAdd1V="formatarMoeda(this.total_recebimento_acordo_novos_parcelado)"
          :subTextAdd2="'Recebido de Acordos - Anteriores: '"
          :subTextAdd2V="formatarMoeda(this.total_recebimento_acordo_antigos)"
          :subTextAdd3="'Total de Acordos - Novos: '"
          :subTextAdd3V="formatarMoeda(this.acordos_novos)"
          :subTextAdd4="'Total de Acordos - Novos (Cancelados):' "
          :subTextAdd4V="formatarMoeda(this.acordos_novos_cancelados)  + ' - ' + calcularPercentual(this.acordos_novos_cancelados, this.acordos_novos ) + '%'"
          :subTextAdd5="'Total de Acordos - Novos (Parcelado):' "
          :subTextAdd5V="formatarMoeda(this.acordos_novos_parcelados)"
          :subTextAdd6="'Total de Comissão Paga:' "
          :subTextAdd3E="'x'"

          :downloadFunction="''"

          />       
     
    </v-col>

    </v-row>

    
    <v-row>
      
      <v-col
      cols="12"
      sm="6"
      lg="6">

        

       <v-card v-if="!recebimento_metodo" color="white" dark style="height: 400px !important;" elevation="1">
        <v-card-title class="text-h5 text--black" style="color: white;background-color: #2f54a4;" >Métodos de Pagamento %</v-card-title>
        <apexcharts type="pie"   height="380"   :options="generatePieChartOptions(this.gf_3_label, this.gf_3_color)" :series="gf_3_series"></apexcharts>
      </v-card>
      <v-card v-if="recebimento_metodo" color="white" dark style="height: 400px !important" elevation="1">
        <v-card-title class="text-h5 text--black" style="color: white;background-color: #2f54a4;" >Tipos de Cancelamento de Plano %</v-card-title>
          <content-loader  primaryColor="#f1f3f7" secondaryColor="#ffffff"  :speed=1 :height=380></content-loader> 
      </v-card>

      </v-col>

      
      <v-col
      cols="12"
      sm="6"
      lg="6">

        

        <v-card v-if="!recebimento_metodo" color="white" dark style="height: 400px !important;" elevation="1">
         <v-card-title class="text-h5 text--black" style="color: white;background-color: #2f54a4;" >Forma de Pagamento no Caixa %</v-card-title>
         <apexcharts type="pie"   height="380"   :options="generatePieChartOptions(this.gf_2_label, this.gf_2_color)" :series="gf_2_series"></apexcharts>
       </v-card>
       <v-card v-if="recebimento_metodo" color="white" dark style="height: 400px !important" elevation="1">
         <v-card-title class="text-h5 text--black" style="color: white;background-color: #2f54a4;" >Tipos de Cancelamento de Plano %</v-card-title>
           <content-loader  primaryColor="#f1f3f7" secondaryColor="#ffffff"  :speed=1 :height=380></content-loader> 
       </v-card>
 
       </v-col>
  

    </v-row>

    <v-row>


    </v-row>


    <v-row>

      <v-col cols="12">
        <v-card  v-if="!load_gf1" color="white" dark elevation="1" style="height: 450px !important;" >
          <v-card-title class="text-h5 text--black" style="color: white;background-color: #2f54a4;">Totais Recebidos - Últimos Meses</v-card-title>
          <v-card-text>
            <!-- Adicione o gráfico aqui -->
            <apexcharts type="line" :height=350 :options="generateLineChartOptions(400, 3200000)" :series="gf_1_series"></apexcharts>
          </v-card-text>
        </v-card>
        <v-card v-if="load_gf1" color="white" dark style="height: 300px !important" elevation="1">
          <v-card-title class="text-h5 text--black" style="color: white;background-color: #2f54a4;" >Grupo de Idade - Titulares</v-card-title>
            <content-loader primaryColor="#f1f3f7" secondaryColor="#ffffff"  :speed=1 :height="300"></content-loader> 
        </v-card>
      </v-col>


    </v-row>

  </v-container>

</v-app> 
</template>
<script>
  import VueApexCharts from "vue-apexcharts";
  import axios from 'axios'
  import Sepultado from '../../services/sepultado'
  import FiltroComponent from "./components/FiltroGeral.vue"; // Certifique-se de que o caminho esteja correto
  import Financeiro from '../../services/financeiro' 
  import { ContentLoader } from 'vue-content-loader'

  export default {
    name: 'Obitos',
    components: {
      apexcharts: VueApexCharts,
      FiltroComponent,
      ContentLoader,

    },

    created() {
      this.selectedStartDate = this.getFirstDayOfMonth(),
      this.selectedEndDate =  this.formatDateBR(new Date())

      this.selectedStartDate2 = this.getFirstDayOfMonth(),
      this.selectedEndDate2 =  this.formatDateBR(new Date())
    },


    
    mounted() {
     this.carregarLoad(),
     this.descobrirMeses();

     this.dashboard();
     this.suaFuncaoPrincipal();
     this.gf01();
     this.gf02();
     this.handleFilterChange(this.selectMode, this.selectedStartDate, this.selectedEndDate, this.selectedStartDate2, this.selectedEndDate2);
    },
   

    data () {
      return {


        gf_3_label: [],
        gf_3_series: [{ data: []}],
        gf_3_color:  ['#FFA533', '#0000CD', '#9B59B6', '#2ECC71', '#1ABC9C', '#8B0000'], 
        recebimento_metodo:true,
        recebimento_metodo_json:"",

        gf_2_label: [],
        gf_2_series: [{ data: []}],
        gf_2_color:  ['#0000FF', '#FFC654', '#8B0000', '#FF8C00', '#2F4F4F', '#A020F0'], 
        recebimento_caixa:true,
        recebimento_caixa_json:"",
        produto: 'Todos',
         bool_01 : true,
         bool_02 : true,
         bool_03 : true,
         bool_04 : true,
         bool_05 : true,
         bool_06 : true,
         bool_07 : true,
         bool_08 : true,
         bool_09 : true,

        acordos_novos: 0,
        acordos_novos_cancelados: 0,
        acordos_novos_parcelados: 0,
        series: [60, 35, 5],
        chartOptions: {
            chart: {
              height: 350,
              type: 'radialBar',
            },
            plotOptions: {
              radialBar: {
                dataLabels: {

                  name: {
                    fontSize: '22px',
                  },
                  value: {
                    fontSize: '16px',
                  },
                  total: {
                    show: true,
                    label: 'Total',
                    formatter: function (w) {
                      // By default this function returns the average of all series. The below is just an example to show the use of custom formatter function
                      return 2000000
                    }
                  }
                }
              }
            },
            labels: ['a', 'b', 'c', 'd'],
          },

         subTextAddDynamic: "Total Recebido:",
         recebido_periodo:0,
         esperado_periodo:0,
         recebido_periodo_vencimento:0,
         recebido_periodo_mongeral:0,
         recebido_periodo_vencimento_anterior:0,
         recebido_periodo_vencimento_posterior:0,

         total_recebimento_acordo_novos_parcelado: 0,
         total_recebimento_acordo_novos_unico: 0,
         total_recebimento_acordo_antigos: 0,

         recebido_acordo:0,
         qtd_recebido_acordo:0,
         perda_cancelamento:0,
         inadimplencia_acumulda:0,

         recebido_periodo_v2:0,
         esperado_periodo_v2:0,
         recebido_periodo_vencimento_v2:0,
         recebido_periodo_vencimento_acordos_v2:0,


         qtd_doc_recebido_periodo_v2:0,
         qtd_contrato_recebido_periodo_v2:0,


         recebido_periodo_vencimento_taxa_v2:0,
         recebido_periodo_vencimento_venda_v2:0,
         esperado_ticket_medio_plano_periodo_v2:0,

         recebido_periodo_vencimento_taxa_caixa_v2:0,
         recebido_periodo_vencimento_venda_caixa_v2:0,
         esperado_ticket_medio_plano_periodo_caixa_v2:0,


         recebido_semseguro_inadimplencia_periodo_v2:0,
         recebido_comseguro_inadimplencia_periodo_v2:0,

         esperado_ticket_medio_jazigo_periodo_caixa_v2:0,


         recebido_adiantado_vencimento_v2:0,
         recebido_periodo_mongeral_v2:0,
         recebido_periodo_vencimento_anterior_v2:0,
         recebido_periodo_vencimento_posterior_v2:0,
         recebido_acordo_v2:0,
         perda_cancelamento_v2:0,
         inadimplencia_acumulda_v2:0,
         inadimplencia_acumulda_aberto_v2:0,
         qtd_inadimplencia_acumulda_aberto_v2:0,
         inadimplencia_acumulda_aberto_acordo_v2:0,
         inadimplencia_acumulda_pago_posterior_v2:0,
         animationInterval: null, // Intervalo para controlar a animação


         

         load_gf1: true,
         load_gf2: false,

          empresa: 'Todas',
          selectedCompany: "Todas",
          selectedType: "Todos",
          selectedOpcao: "Todas",
          opcoes: ["Todas","Campanha 50 Anos"],
          selectMode: "Vencimento",
          selectedStartDate : "",
          selectedEndDate : "",
          isLoading: false,
          modos: ["Período"],
          modos2: ["SIM", "NÃO"],
          campos: 'N',
          categoria: [],
          showFilter: false,
          showFilterDownload: false,
          empresas: ["CEMITERIO PREVIDA", "MEMORIAL PARQUE MACEIÓ", "PARQUE DO AGRESTE", "PREVIDA", "A FLOR DO CAFÉ", "Todas"],
          tipos: ["Todos", "Planos", "Planos Florais", "Jazigo", "Cremação", "Funerária", "Serviços Administrativos", "Serviços Cemiteriais"],
          tipos_funerarios: [
                "Todos",
                "COMPLETO",
                "CONCESSÃO DE COLUMBÁRIO",
                "CONCESSÃO DE JAZIGOS",
                "CONCESSÃO DE OSSÁRIO",
                "CREMAÇÃO",
                "CREMAÇÃO COM CERIMÔNIA",
                "CREMAÇÃO SEM CERIMÔNIA",
                "FAMILIA",
                "INTEGRAL",
                "P23",
                "PARQUE TOTAL",
                "PF - AMPARO",
                "PROTEÇÃO",
                "PV - AMIGO",
                "PV - BRONZE",
                "PV - CREMAÇÃO COM CERIMÔNIA",
                "PV - DIAMANTE",
                "PV - EMPRESARIAL",
                "PV - EMPRESARIAL - EMPRESA",
                "PV - ESPECIAL",
                "PV - ESMERALDA",
                "PV - ESSENCIAL",
                "PV - IDEAL",
                "PV - MAIOR",
                "PV - MAIS",
                "PV - MASTER GOLD",
                "PV - MELHOR",
                "PV - PLANO PJ",
                "PV - PLUS",
                "PV - POLICIA MILITAR",
                "PV - PRATA",
                "PV - PREVIDA",
                "PV - PREVIPARQ DO AGRESTE",
                "PV - RUBI",
                "PV - SÃO MATHEUS",
                "URNAS CINERARIAS",
                "VENDA DE COLUMBÁRIO",
                "VENDA DE JAZIGOS - IMEDIATO",
                "VENDA DE JAZIGOS - PREVENTIVO",
                "VENDA DE JAZIGOS - TRANSITÓRIO"
              ],

          gf_1_series: [
                      {
                        name: "Memorial Parque Maceió",
                        data: [],
                        color: '#008000',
                      },
                      {
                        name: "Previda",
                        data: [],
                        color: '#0000FF',
                      },
                      {
                        name: "Parque do Agreste",
                        data: [],
                        color: '#808080',
                      },
                      {
                        name: "Cemiterio Previda",
                        data: [],
                        color: '#800080',
                      },
                        {
                        name: "Parque das Flores",
                        data: [],
                        color: '#FF8C00',
                      },
                    ],
            
          lista_valores_mes_gf: {
                      valores: [],
           },

           lista_nome_empresa:[],
      
      }
    },

   
    methods: {
     
      
      async suaFuncaoPrincipal() {
          // Inicialize this.load_gf1 como true antes de iniciar as chamadas
          this.load_gf1 = true;

          // Crie um array para armazenar as promessas das 4 chamadas
          const promises = [];

          // Inicie as 4 chamadas e armazene suas promessas
          promises.push(this.consultarRecebimentoPorMes("Memorial Parque Maceió", 0));
          promises.push(this.consultarRecebimentoPorMes("Previda", 1));
          promises.push(this.consultarRecebimentoPorMes("parque do Agreste", 2));
          promises.push(this.consultarRecebimentoPorMes("Cemiterio Previda", 3));
          promises.push(this.consultarRecebimentoPorMes("Parque das Flores", 4));

          // Aguarde todas as promessas serem resolvidas
          await Promise.all(promises);

          this.atualizarGrafico(0); // Atualize a série na posição 0
          this.atualizarGrafico(1); // Atualize a série na posição 1
          this.atualizarGrafico(2); // Atualize a série na posição 1
          this.atualizarGrafico(3); // Atualize a série na posição 1
          this.atualizarGrafico(4); // Atualize a série na posição 1

        },


        isDateValid(dateString) {
          // Verifica se a string é uma data válida
          const date = new Date(dateString);
          return !isNaN(date.getTime());
        },
        areDatesValid(dates) {
          // Verifica se todas as datas na matriz são válidas
          return dates.every(dateString => {
            const date = new Date(dateString);
            return !isNaN(date.getTime());
          });
        },

        handleFilterChange(selectedFilter, dataI, dataF, DataRI, DataRF) {

         

          if (selectedFilter === 'Vencimento') {
            this.subTextAddDynamic = 'Total Recebido: ';
          } else if (selectedFilter != 'Vencimento') {

            if (
              converteDataParaFormatoISO(DataRI) <= converteDataParaFormatoISO(dataI) &&
              converteDataParaFormatoISO(DataRF) > converteDataParaFormatoISO(dataF)
              ) {
                this.subTextAddDynamic = 'Total Recebido com Acréscimo: ';
              }else{
                this.subTextAddDynamic = 'Total Recebido: ';

              }
          }

        },

        generatePieChartOptions(labels, colors) {
            return {
              chart: {
                width: 440, // Largura fixa
                height: 380, // Altura fixa
                type: 'pie',
              },
              labels: labels,
              plotOptions: {
                pie: {
                  dataLabels: {
                    offset: -10
                  }
                }
              },
              colors: colors, // Defina as cores desejadas aqui
              dataLabels: {
                formatter(val, opts) {
                  const name = opts.w.globals.labels[opts.seriesIndex];
                  return [val.toFixed(1) + '%'];
                }
              },
              legend: {
                position: 'bottom' // Posição correta para rodapé
              },
              responsive: [{
                breakpoint: 400,
                options: {
                  chart: {
                    width: 500
                  },
                  legend: {
                    position: 'bottom' // Certifique-se de usar 'bottom' aqui também
                  }
                }
              }]
            };
          },

        atualizarGrafico(pos) {

          const valorArmazenado = sessionStorage.getItem('lista_meses_rec');
          const listaNomeEmpresaString = sessionStorage.getItem('lista_nome_empresa');
          this.lista_nome_empresa = JSON.parse(listaNomeEmpresaString);

          if (valorArmazenado !== undefined && valorArmazenado !== null) {
              const valoresObj = JSON.parse(valorArmazenado);

              if (valoresObj.valores[pos]) {
                const valores = valoresObj.valores[pos][0];
                this.gf_1_series[pos].data = valores;
                
              }

              if(pos == 3){
                this.load_gf1 = false;
              }
            }
            
      },

      download_card1(){
        this.showFilterDownload = true;
      },
      filterData(valores) {
        
          this.carregarLoad(),
          this.isLoading = true;
          let nova_consulta = false;

          this.selectedType = valores.selectedType === "Jazigo" ? "Cemitérios" : valores.selectedType;
          this.selectedOpcao = valores.selectedOpcao;
          this.selectedCompany = valores.selectedCompany.join(",");
          this.selectMode = valores.selectMode;

          this.selectMode = "Vencimento";


          this.handleFilterChange(valores.selectMode, valores.selectedStartDate, valores.selectedEndDate, valores.selectedStartDate2, valores.selectedEndDate2);
          this.selectedStartDate = valores.selectedStartDate;
          this.selectedEndDate = valores.selectedEndDate;
          this.selectedStartDate2 = valores.selectedStartDate2;
          this.selectedEndDate2 = valores.selectedEndDate2;
          this.selectedStartDate3 = valores.selectedStartDate3;
          this.selectedEndDate3 = valores.selectedEndDate3;
          this.produto = valores.selectedProduto;
          //this.gf01();
          this.dashboard();
          this.gf01();
          this.gf02();
      },

      filtrarTotalGraficos(lista, adesao, tipo, empresa, campoLabel, campoSerie, gf) {
         let totalAcumulado = 0;
 
           const newLabels = [];
           const newSeries = [];
 
         
           for (const item of lista) {
          
                const index = newLabels.indexOf(item[campoLabel]);

                if (index !== -1) {
                  newSeries[index] += item[campoSerie];
                } else {
                  newLabels.push(item[campoLabel]);
                  newSeries.push(item[campoSerie]);
                }
              
            }


             if(gf == 3){
             
                try {
                  this.gf_3_label = newLabels;
                  this.gf_3_series = newSeries;
                  this.recebimento_metodo = false;
                } catch (error) {
                  console.error('Erro ao definir gf_3_label:', error);
                        console.error(error.stack);                  
                        console.log(error.stack);                   }

             }else if(gf == 2){
                  try {
                      this.gf_2_label = newLabels;
                      this.gf_2_series = newSeries;
                      this.recebimento_caixa = false;
                    }catch (error) {
                      console.error('Erro ao definir gf_2_label:', error);
                        console.error(error.stack);                  
                        console.log(error.stack);                   
                      }
                    

                }
 
               

             
       },

       async gf01() {
          try {
            const response = await Financeiro.total_recebimento_metodo(converteDataParaFormatoISO(this.selectedStartDate), converteDataParaFormatoISO(this.selectedEndDate),  this.selectedCompany, this.selectedType, "Todos", this.selectedOpcao, this.produto);
            if (response.status === 200) {
              // Extrair os dados da resposta da API
              // Extrair os dados da resposta da API
              this.recebimento_metodo_json = response.data;
            this.$nextTick(() => {
              this.filtrarTotalGraficos(this.recebimento_metodo_json, "Todos", "Todos", this.selectedCompany, "label", "qtd", "3");
            });

            }
          } catch (error) {
            // Lidar com erros, se necessário
            console.error(error);
          }finally {
            }
        },

        async gf02() {
          try {
            const response = await Financeiro.total_recebimento_caixa_forma(converteDataParaFormatoISO(this.selectedStartDate), converteDataParaFormatoISO(this.selectedEndDate),  this.selectedCompany, this.selectedType, "Todos", this.selectedOpcao, this.produto);

            if (response.status === 200) {
              // Extrair os dados da resposta da API
              // Extrair os dados da resposta da API
              this.recebimento_caixa_json = response.data;
              this.$nextTick(() => {
              this.filtrarTotalGraficos(this.recebimento_caixa_json, "Todos", "Todos", this.selectedCompany, "label", "qtd", "2");                      
              });

            }
          } catch (error) {
            // Lidar com erros, se necessário
            console.error(error);
          }finally {
            }
        },

        
     
      getFirstDayOfMonth() {
        const dataAtual = new Date();
        const primeiroDiaDoMes = new Date(dataAtual.getFullYear(), dataAtual.getMonth(), 1);
        return this.formatDateBR(primeiroDiaDoMes);
        },

        startAnimation(targetValue) {
            this.animationInterval = setInterval(() => {
              if (this.animatedValue < targetValue) {
                this.animatedValue += 1; // Atualiza o valor animado
              } else {
                clearInterval(this.animationInterval); // Interrompe a animação quando atingir o valor desejado
              }
            }, 10); // Intervalo em milissegundos entre cada atualização
          },


        formatDateBR(date) {
                if (!date) return '';
                const parsedDate = new Date(date);
                const day = String(parsedDate.getDate()).padStart(2, '0'); // Adiciona zero à esquerda, se necessário
                const month = String(parsedDate.getMonth() + 1).padStart(2, '0'); // Adiciona zero à esquerda, se necessário
                const year = parsedDate.getFullYear();
                return `${day}/${month}/${year}`;
            },

        formatDateBRAPI(date) {
                if (!date) return '';
                const parsedDate = new Date(this.formatDateBR(date));
                const year = parsedDate.getFullYear();
                const month = String(parsedDate.getMonth() + 1).padStart(2, '0'); // Adiciona zero à esquerda, se necessário
                const day = String(parsedDate.getDate()).padStart(2, '0'); // Adiciona zero à esquerda, se necessário
                return `${year}-${month}-${day}`;
              },



      carregarLoad(){

         this.bool_01 = true,
         this.bool_02 = true,
         this.bool_03 = true,
         this.bool_04 = true,
         this.bool_05 = true,
         this.bool_06 = true,
         this.bool_07 = true,
         this.bool_08 = true,
         this.bool_09 = true

      },

      async dashboard() {


              this.selectMode = "Vencimento";
              const formattedStartDate = converteDataParaFormatoISO(this.selectedStartDate);
              const formattedEndDate = converteDataParaFormatoISO(this.selectedEndDate);

              const formattedStartDate2 = converteDataParaFormatoISO(this.selectedStartDate2);
              const formattedEndDate2 = converteDataParaFormatoISO(this.selectedEndDate2);



                      const diferencaEmDias = this.calcularDiferencaEntreDatas(formattedStartDate, formattedEndDate);

                      if (diferencaEmDias > 40 && diferencaEmDias < 1) {
                        this.isLoading = false;
                        this.showFilter = true;
                        this.showProgress = false;
                        return 
                      }

                      Financeiro.total_recebimento_periodo_v2(formattedStartDate, formattedEndDate, this.selectedCompany, this.selectedType, "Todos", this.selectedOpcao, this.produto).then(response => {

                          if(response.status === 200){
                            this.recebido_periodo_v2 = response.data;    
                          } 

                        }).catch(e => {    
                        }).finally(() => {
                          this.bool_02 = false;
                      })  

                      Financeiro.total_recebimento_vencimento_v2(formattedStartDate, formattedEndDate, this.selectedCompany, this.selectedType, this.selectMode, formattedStartDate2, formattedEndDate2, this.selectedOpcao, this.produto).then(response => {

                        if(response.status === 200){
                          this.recebido_periodo_vencimento_v2 = response.data;      
                        } 

                        }).catch(e => {    
                        }).finally(() => {
                      })  


                      Financeiro.total_recebido_periodo_vencimento_acordos_v2(formattedStartDate, formattedEndDate, this.selectedCompany, this.selectedType, this.selectMode, formattedStartDate2, formattedEndDate2, this.selectedOpcao, this.produto).then(response => {

                      if(response.status === 200){
                        this.recebido_periodo_vencimento_acordos_v2 = response.data;      
                      } 

                      }).catch(e => {    
                      }).finally(() => {
                      })  

                      
                      Financeiro.total_recebimento_acordo_novos_parcelado(formattedStartDate, formattedEndDate, this.selectedCompany, this.selectedType, this.selectMode,  this.selectedOpcao, this.produto).then(response => {

                        if(response.status === 200){
                          this.total_recebimento_acordo_novos_parcelado = response.data;      
                        } 

                        }).catch(e => {    
                        }).finally(() => {
                        })  

                        Financeiro.total_recebimento_acordo_novos_unico(formattedStartDate, formattedEndDate, this.selectedCompany, this.selectedType, this.selectMode,  this.selectedOpcao, this.produto).then(response => {

                        if(response.status === 200){
                          this.total_recebimento_acordo_novos_unico = response.data;      
                        } 

                        }).catch(e => {    
                        }).finally(() => {
                        })  

                        Financeiro.total_recebimento_acordo_antigos(formattedStartDate, formattedEndDate, this.selectedCompany, this.selectedType, this.selectMode, this.selectedOpcao, this.produto).then(response => {

                        if(response.status === 200){
                          this.total_recebimento_acordo_antigos = response.data;      
                        } 

                        }).catch(e => {    
                        }).finally(() => {
                        })  

                      /*

                      

         total_recebimento_acordo_novos_parcelado: 0,
         total_recebimento_acordo_novos_unico: 0,
         total_recebimento_acordo_antigos: 0,
                      Financeiro.total_contrato_recebimento_periodo_v2(formattedStartDate, formattedEndDate, this.selectedCompany, this.selectedType, this.selectMode, formattedStartDate2, formattedEndDate2, this.selectedOpcao, this.produto).then(response => {

                        if(response.status === 200){
                          this.qtd_contrato_recebido_periodo_v2 = response.data;      
                        } 

                        }).catch(e => {    
                        }).finally(() => {
                        })  


               

                      */
                      
                      Financeiro.total_doc_recebimento_periodo_v2(formattedStartDate, formattedEndDate, this.selectedCompany, this.selectedType, this.selectMode, this.selectedOpcao, this.produto).then(response => {

                      if(response.status === 200){
                        this.qtd_doc_recebido_periodo_v2 = response.data;      
                      } 

                      }).catch(e => {    
                      }).finally(() => {
                      })  
                      

                      Financeiro.total_acordos_novos(formattedStartDate, formattedEndDate, this.selectedCompany, this.selectedType, this.selectMode, this.selectedOpcao, this.produto).then(response => {

                        if(response.status === 200){
                          this.acordos_novos = response.data;      
                        } 

                        }).catch(e => {    
                        }).finally(() => {
                        })  


                        Financeiro.total_acordos_novos_cancelados(formattedStartDate, formattedEndDate, this.selectedCompany, this.selectedType, this.selectMode, this.selectedOpcao, this.produto).then(response => {

                          if(response.status === 200){
                            this.acordos_novos_cancelados = response.data;      
                          } 

                          }).catch(e => {    
                          }).finally(() => {
                          }) 


                          Financeiro.total_acordos_novos_parcelado(formattedStartDate, formattedEndDate, this.selectedCompany, this.selectedType, this.selectMode, this.selectedOpcao, this.produto).then(response => {

                            if(response.status === 200){
                              this.acordos_novos_parcelados = response.data;      
                            } 

                            }).catch(e => {    
                            }).finally(() => {
                            }) 

                      
                      Financeiro.total_recebido_periodo_vencimento_taxa_v2(formattedStartDate, formattedEndDate, this.selectedCompany, this.selectedType, this.selectMode, formattedStartDate2, formattedEndDate2, this.selectedOpcao, this.produto).then(response => {

                        if(response.status === 200){
                          this.recebido_periodo_vencimento_taxa_v2 = response.data;      
                        } 

                        }).catch(e => {    
                        }).finally(() => {
                        })  

                        Financeiro.total_recebido_periodo_vencimento_venda_v2(formattedStartDate, formattedEndDate, this.selectedCompany, this.selectedType, this.selectMode, formattedStartDate2, formattedEndDate2, this.selectedOpcao, this.produto).then(response => {

                        if(response.status === 200){
                          this.recebido_periodo_vencimento_venda_v2 = response.data;      
                        } 

                        }).catch(e => {    
                        }).finally(() => {
                        })  

                        Financeiro.ticket_medio_plano_periodo_vencimento_v2(formattedStartDate, formattedEndDate, this.selectedCompany, this.selectedType, this.selectMode, this.selectedOpcao, this.produto).then(response => {

                          if(response.status === 200){
                            this.esperado_ticket_medio_plano_periodo_v2 = response.data;      
                          } 

                          }).catch(e => {    
                          }).finally(() => {
                          })  

                          Financeiro.total_recebido_periodo_vencimento_taxa_caixa_v2(formattedStartDate, formattedEndDate, this.selectedCompany, this.selectedType, this.selectMode, formattedStartDate2, formattedEndDate2, this.selectedOpcao, this.produto).then(response => {

                            if(response.status === 200){
                              this.recebido_periodo_vencimento_taxa_caixa_v2 = response.data;      
                            } 

                            }).catch(e => {    
                            }).finally(() => {
                          })  

                          Financeiro.total_recebido_periodo_vencimento_venda_caixa_v2(formattedStartDate, formattedEndDate, this.selectedCompany, this.selectedType, this.selectMode, formattedStartDate2, formattedEndDate2, this.selectedOpcao, this.produto).then(response => {

                            if(response.status === 200){
                              this.recebido_periodo_vencimento_venda_caixa_v2 = response.data;      
                            } 

                            }).catch(e => {    
                            }).finally(() => {
                          })  

                          Financeiro.total_comseguro_inadimplencia_periodo_v2(formattedStartDate, formattedEndDate, this.selectedCompany, this.selectedType, this.selectMode, this.selectedOpcao, this.produto).then(response => {

                            if(response.status === 200){
                              this.recebido_comseguro_inadimplencia_periodo_v2 = response.data;      
                            } 

                            }).catch(e => {    
                            }).finally(() => {
                            })  

                          Financeiro.total_semseguro_inadimplencia_periodo_v2(formattedStartDate, formattedEndDate, this.selectedCompany, this.selectedType, this.selectMode, this.selectedOpcao, this.produto ).then(response => {

                            if(response.status === 200){
                              this.recebido_semseguro_inadimplencia_periodo_v2 = response.data;      
                            } 

                            }).catch(e => {    
                            }).finally(() => {
                            })  

                          Financeiro.ticket_medio_plano_periodo_vencimento_caixa_v2(formattedStartDate, formattedEndDate, this.selectedCompany, this.selectedType, this.selectMode, formattedStartDate2, formattedEndDate2, this.selectedOpcao, this.produto).then(response => {

                              if(response.status === 200){
                                this.esperado_ticket_medio_plano_periodo_caixa_v2 = response.data;      
                              } 

                              }).catch(e => {    
                              }).finally(() => {
                          })  

                          Financeiro.ticket_medio_jazigo_periodo_vencimento_caixa_v2(formattedStartDate, formattedEndDate, this.selectedCompany, this.selectedType, this.selectMode, formattedStartDate2, formattedEndDate2, this.selectedOpcao, this.produto).then(response => {

                            if(response.status === 200){
                              this.esperado_ticket_medio_jazigo_periodo_caixa_v2 = response.data;      
                            } 

                            }).catch(e => {    
                            }).finally(() => {
                            })  
                        


                  
                      Financeiro.total_recebimento_periodo_anterior_v2(formattedStartDate, formattedEndDate, this.selectedCompany, this.selectedType, this.selectMode, formattedStartDate2, formattedEndDate2, this.selectedOpcao, this.produto).then(response => {

                          if(response.status === 200){
                              this.$nextTick(() => {
                                this.recebido_periodo_vencimento_anterior_v2 = response.data; 
                             })                               
                          } 

                          }).catch(e => {    
                          }).finally(() => {
                            this.bool_05 = false;
                          })  

                      Financeiro.total_recebiment_periodo_mongeral(formattedStartDate, formattedEndDate, this.selectedCompany, this.selectedType, "Todos", this.selectedOpcao, this.produto).then(response => {

                          if(response.status === 200){
                            this.recebido_periodo_mongeral = response.data;                              
                          } 

                          }).catch(e => {    
                          }).finally(() => {
                            this.bool_08 = false;
                          })  


                          

                    Financeiro.total_recebimento_periodo_posterior_v2(formattedStartDate, formattedEndDate, this.selectedCompany, this.selectedType, this.selectMode, formattedStartDate2, formattedEndDate2, this.selectedOpcao, this.produto).then(response => {

                            if(response.status === 200){
                              this.recebido_periodo_vencimento_posterior_v2 = response.data;                                
                            } 

                            }).catch(e => {    
                            }).finally(() => {
                              this.bool_06 = false;
                            })  

               

                    Financeiro.total_recebimento_acordo(formattedStartDate, formattedEndDate, this.selectedCompany, this.selectedType, "Todos",  this.selectedOpcao, this.produto).then(response => {

                          if(response.status === 200){
                            this.recebido_acordo = response.data;                                
                          } 

                          }).catch(e => {    
                          }).finally(() => {
                            this.bool_07 = false;
                          })  
                          Financeiro.qtd_recebimento_acordo(formattedStartDate, formattedEndDate, this.selectedCompany, this.selectedType, "Todos",  this.selectedOpcao, this.produto).then(response => {

                            if(response.status === 200){
                              this.qtd_recebido_acordo = response.data;                                
                            } 

                            }).catch(e => {    
                            }).finally(() => {
                              this.bool_07 = false;
                            })  

                    Financeiro.total_inadimplencia_periodo_v2(formattedStartDate, formattedEndDate, this.selectedCompany, this.selectedType, this.selectMode, formattedStartDate2, formattedEndDate2, this.selectedOpcao, this.produto).then(response => {

                          if(response.status === 200){
                            this.inadimplencia_acumulda_v2 = response.data;                                
                          } 

                          }).catch(e => {    
                          }).finally(() => {
                            this.bool_03 = false;
                          })  

                          // total_inadimplencia_periodo_aberto_acordo_v2

                          Financeiro.total_inadimplencia_periodo_aberto_v2(formattedStartDate, formattedEndDate, this.selectedCompany, this.selectedType, this.selectMode, formattedStartDate2, formattedEndDate2, this.selectedOpcao, this.produto).then(response => {

                                if(response.status === 200){
                                  this.inadimplencia_acumulda_aberto_v2 = response.data;                                
                                } 

                                }).catch(e => {    
                                }).finally(() => {
                                }) 

                      Financeiro.qtd_inadimplencia_periodo_aberto_v2(formattedStartDate, formattedEndDate, this.selectedCompany, this.selectedType, this.selectMode, formattedStartDate2, formattedEndDate2, this.selectedOpcao, this.produto).then(response => {

                                  if(response.status === 200){
                                    this.qtd_inadimplencia_acumulda_aberto_v2 = response.data;                                
                                  } 

                                  }).catch(e => {    
                                  }).finally(() => {
                                  }) 

                         Financeiro.total_inadimplencia_periodo_aberto_acordo_v2(formattedStartDate, formattedEndDate, this.selectedCompany, this.selectedType, this.selectMode, formattedStartDate2, formattedEndDate2, this.selectedOpcao, this.produto).then(response => {

                                if(response.status === 200){
                                  this.inadimplencia_acumulda_aberto_acordo_v2 = response.data;                                
                                } 

                                }).catch(e => {    
                                }).finally(() => {
                                })  


                          Financeiro.total_inadimplencia_periodo_pago_posterior_v2(formattedStartDate, formattedEndDate, this.selectedCompany, this.selectedType, this.selectMode, formattedStartDate2, formattedEndDate2, this.selectedOpcao, this.produto).then(response => {

                              if(response.status === 200){
                                this.inadimplencia_acumulda_pago_posterior_v2 = response.data;                                
                              } 

                              }).catch(e => {    
                              }).finally(() => {
                              }) 

                                
                          

                    Financeiro.total_esperado_periodo_v2(formattedStartDate, formattedEndDate, this.selectedCompany, this.selectedType, "Todos", this.selectedOpcao, this.produto).then(response => {

                          if(response.status === 200){
                            this.esperado_periodo_v2 = response.data;                                
                          } 

                          }).catch(e => {    
                          }).finally(() => {
                            this.bool_01 = false;
                          })  

                    Financeiro.total_perda_cancelamento(formattedStartDate, formattedEndDate, this.selectedCompany, this.selectedType, "Todos", this.selectedOpcao, this.produto).then(response => {

                            if(response.status === 200){
                              console.log("total_perda_cancelamento");

                              console.log(response.data);
                              this.perda_cancelamento = response.data;                                
                            } 

                            }).catch(e => {    
                            }).finally(() => {
                              this.bool_09 = false;
                            })  


                          


                          

      },
   
    
      calcularTotalAcumuladoFuneraria(lista) {

        this.funeraria_previda = 0;

          for (const item of lista) {

            if (item.label === 'PREVIDA') {

              this.funeraria_previda += item.qtd;
            }
          }



      },

      formatarMoeda(valor) {
        // Verifica se o valor é numérico
        if (isNaN(valor)) {
            return 'Valor inválido';
        }

        // Formata o valor como moeda brasileira (BRL)
        return valor.toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL'
        });
    },

    calcularPercentual(valorParcial, valorTotal) {
      if (valorTotal === 0) {
        return 0; // Evitar divisão por zero
      }
      return ((valorParcial / valorTotal) * 100).toFixed(2);
    },
     


      calcularDiferencaEntreDatas(dataInicial, dataFinal) {
                const data1 = new Date(dataInicial);
                const data2 = new Date(dataFinal);

                // Verifique se as datas são válidas
                if (isNaN(data1) || isNaN(data2)) {
                    return NaN; // Retorna NaN se uma das datas for inválida
                }

                const diferencaEmMilissegundos = data2 - data1;
                const diferencaEmDias = diferencaEmMilissegundos / (1000 * 60 * 60 * 24);

                return diferencaEmDias;
      },


        clear () {
          this.$refs.form.reset();
        },

        descobrirMeses() {
          const anoAtual = new Date().getFullYear(); // Obtém o ano atual
          const mesAtual = new Date().getMonth(); // Obtém o ano atual

          for (let mes = (mesAtual-4); mes <= mesAtual; mes++) {
            this.categoria.push(formatMonthName(mes));
          }

        },

        async consultarRecebimentoPorMes(empresa, pos) {

            const anoAtual = new Date().getFullYear();
            const mesAtual = new Date().getMonth();
            const valorArmazenado = sessionStorage.getItem('lista_meses_rec');

            if (valorArmazenado !== undefined && valorArmazenado !== null) {
              const valoresObj = JSON.parse(valorArmazenado);

              if (valoresObj.valores[pos]) {
                const valores = valoresObj.valores[pos][0];
                this.gf_1_series[pos].data = valores;
                
              }

              return;
            }

            const vl_meses = [];

            const promises = [];

            for (let mes = mesAtual - 4; mes <= mesAtual; mes++) {
              const startDate = this.formatDateBR(new Date(anoAtual, mes - 1, 1));
              const lastDay = this.formatDateBR(new Date(anoAtual, mes, 0));
              
              if(empresa === 'Previda'){
                const options = { month: 'long' };
                const nomeMes = new Date(anoAtual, mes - 1, 1).toLocaleString('pt-BR', options);
                this.lista_nome_empresa.push(nomeMes);
              }


              promises.push(
                Financeiro.total_recebimento_periodo(
                  converteDataParaFormatoISO(startDate),
                  converteDataParaFormatoISO(lastDay),
                  empresa,
                  this.selectedType,
                  "Todos",
                  "Todas"
                )
                  .then((response) => {
                    if (response.status === 200) {
                      const valorDoMes = response.data;
                      vl_meses.push(valorDoMes);
                    }
                  })
                  .catch((e) => {
                    console.error(e);
                  })
              );
            }

            await Promise.all(promises);

            if (!this.lista_valores_mes_gf.valores[pos]) {
              this.lista_valores_mes_gf.valores[pos] = [];
            }

            this.lista_valores_mes_gf.valores[pos].push(vl_meses);

            sessionStorage.setItem('lista_meses_rec', JSON.stringify(this.lista_valores_mes_gf));
            const listaNomeEmpresaString = JSON.stringify(this.lista_nome_empresa);
            sessionStorage.setItem('lista_nome_empresa', listaNomeEmpresaString);

          },


        generateLineChartOptions(altura, vlMax) {

                  
                return {
                    chart: {
                        height: altura,
                        type: 'line',
                        dropShadow: {
                          enabled: true,
                          color: '#000',
                          top: 18,
                          left: 7,
                          blur: 10,
                          opacity: 0.2
                        },
                        toolbar: {
                          show: true
                        }
                      },
                      dataLabels: {
                        enabled: true,
                        enabledOnSeries: [0, 1, 2, 3, 4], // Índices das séries às quais aplicar rótulos
                        style: {
                          fontSize: '12px',
                          colors: ['red']
                        },
                        formatter: formatMoeda // Use a função de formatação personalizada para rótulos de eixo y

                      },
                   
                      stroke: {
                        curve: 'smooth'
                      },
                      title: {
                       // text: 'Average High & Low Temperature',
                        align: 'left'
                      },
                      grid: {
                        borderColor: '#e7e7e7',
                        row: {
                          colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                          opacity: 0.5
                        },
                      },
                      markers: {
                        size: 1
                      },
                      xaxis: {
                        categories: this.lista_nome_empresa ,
                        title: {
                          text: 'MESES'
                        }
                      },
                      yaxis: {
                        title: {
                          text: ''
                        },
                        min: 0,
                        max: vlMax,
                        labels: {
                              formatter: formatMoeda // Use a função de formatação personalizada para rótulos de eixo y
                          }
                      },
                      tooltip: {
                          y: {
                              formatter: formatMoeda // Use a função de formatação personalizada para rótulos dos pontos no gráfico
                          },
                          
                      },
                      legend: {
                        position: 'top',
                        horizontalAlign: 'right',
                        floating: true,
                        offsetY: -25,
                        offsetX: -5
                      },
                   
                };

           
              }

     
    },
  }

   
  function formatMoeda(value) {
                      // Use o método toLocaleString com a opção style: 'currency' para formatar como moeda

                      if (value === undefined || value === null) {
                          return 'R$ 0,00'; // Ou você pode retornar 0 como número, se preferir.
                        }

                      return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
                      //return 0
                      //return value
                  }

  function getMonthName(monthNumber) {
    const monthNames = [
        "Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho",
        "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"
    ];

    if (monthNumber >= 1 && monthNumber <= 12) {
        return monthNames[monthNumber - 1];
    } else {
        return ""; // Retorna uma string vazia para números de mês inválidos
    }
}

  function parseDate(dateString) {
    const parts = dateString.split('/');
    // Lembre-se que os meses em JavaScript começam do zero
    const year = parseInt(parts[2], 10);
    const month = parseInt(parts[1], 10) - 1;
    const day = parseInt(parts[0], 10);
    return new Date(year, month, day);
  }

  function converteDataParaFormatoISO(dataNoFormatoBR) {
              const partes = dataNoFormatoBR.split('/');
              if (partes.length === 3) {
                const [dia, mes, ano] = partes;
                return `${ano}-${mes}-${dia}`;
              }
              return null; // Retorna null se a data estiver em um formato inválido
            }

  function formatMonthName(month) {
    const monthNames = [
        "JAN", "FEV", "MAR", "ABR", "MAI", "JUN",
        "JUL", "AGO", "SET", "OUT", "NOV", "DEZ"
    ];

    if (month >= 1 && month <= 12) {
        return monthNames[month - 1];
    } else {
        return ""; // Retorna uma string vazia para números de mês inválidos
    }
  }


</script>
